import apiService from '@main/api/index';
import { URL_PREFIX } from './index';

const PREMIUM_OFFERS_URL = `${URL_PREFIX}/api/payments/premium_offers/`;
const PAYMENT_UPDATE_URL = `${URL_PREFIX}/api/vp/payment/`;
const CREATE_PREMIUM_PAYMENT_URL = `${URL_PREFIX}/api/payments/premium/create/`;
const CREATE_UPDATE_PAYMENT_URL = `${URL_PREFIX}/api/payments/updates/create/`;

/**
 * Создание платежа за премиум аккаунт
 * @param {number} priceId Id тарифа в системе
 * @param {string} redirectParam Редирект после платежа
 */
export async function CreatePremiumPayment(priceId) {
  const response = await apiService.post(CREATE_PREMIUM_PAYMENT_URL, {
    price_id: priceId,
  });
  return response;
}

/**
 * Создание платежа за обновление выдачи
 * @param {number} paymentId id тарифа в системе
 * @param {string} redirectParam Редирект после платежа
 */
export async function CreateUpdatePayment(paymentId, redirectParam) {
  const response = await apiService.post(CREATE_UPDATE_PAYMENT_URL, {
    price_id: paymentId,
    redirect: redirectParam,
  });
  return response;
}
