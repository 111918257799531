<template>
  <!--div :class="['big-popup', 'example-stories', 'wrap-popup', open ? 'open' : '']"-->
  <Modal title="Пример заполнения" :show="open" @hide="() => $emit('close')">
    <div class="wrap-popup__inner medium-popup__inner">
      <div id="example-wrap" class="big-popup__body screenshots__inner scroll">
        <div ref="stories-example"></div>
        Пример заполнения <b>охватов сторис</b> <br />
        <br />
        <img src="@main/assets/img/settings/screenshots__inner01.png" alt="img" />
        <br />
        <br />
        Модератор не примет просмотры <span class="red">вместо <b>охватов</b> </span>. <br /><br />
        В статистике находите минимальное и максимальное число охватов сторис за последние 14 дней.
        Заполняете в соответствующие поля и загружаете скриншот.
        <br /><br />
        Пример скриншота с охватами сторис:
        <br /><br />
        <img src="@main/assets/img/settings/screenshots__inner02.png" alt="img" />
        <br />
        <br />
        <div ref="posts-example"></div>
        Пример заполнения <b>охватов постов</b> <br />
        <br />
        <img src="@main/assets/img/settings/screenshots__inner03.png" alt="img" />
        <br />
        <br />
        Модератор не примет просмотры <span class="red">вместо охватов.</span> <br />
        <br />
        В статистике находите минимальное и максимальное число охватов постов за последние 30 дней.
        Заполняете в соответствующие поля и загружаете скриншот.
        <br />
        <br />
        Пример скриншота с охватами постов:
        <br />
        <br />
        <img src="@main/assets/img/settings/screenshots__inner04.png" alt="img" />
        <br />
        <br />
        <div ref="women-example"></div>
        Пример заполнения <b>процента женщин</b> <br />
        <br />
        <img src="@main/assets/img/settings/screenshots__inner05.png" alt="img" />
        <br />
        <br />
        Модератор не примет скриншот без <span class="red"><b>процентов</b></span
        >. <br />
        <br />
        В статистике вашего аккаунта находите процент женщин. Заполняете в соответствующее поле и
        загружаете скриншот.
        <br />
        <br />
        Пример скриншота с процентом женщин:
        <br />
        <br />
        <img src="@main/assets/img/settings/screenshots__inner06.png" alt="img" />
        <br />
        <br />
        <div ref="audience-example"></div>
        Пример заполнения <b>преобладающего возраста в процентах</b> <br />
        <br />
        <img src="@main/assets/img/settings/screenshots__inner07.png" alt="img" />
        <br />
        <br />
        Модератор не примет скриншот без <span class="red"><b>процентов</b></span
        >. <br />
        <br />
        В статистике вашего аккаунта находите стороку с преобладающим возрастом в процентах.
        Заполняете в соответствующие поля и загружаете скриншот.
        <br />
        <br />
        Пример скриншота с преобладающим возрастом:
        <br />
        <br />
        <img src="@main/assets/img/settings/screenshots__inner08.png" alt="img" />
        <br />
        <br />
        <div ref="country-example"></div>
        Пример заполнения <b>преобладающей страны в процентах</b> <br />
        <br />
        <img src="@main/assets/img/settings/screenshots__inner09.png" alt="img" />
        <br />
        <br />
        Модератор не примет скриншот без <span class="red"><b>процентов</b></span
        >. <br />
        <br />
        В статистике вашего аккаунта находите стороку с преобладающей страной в процентах.
        Заполняете в соответствующие поля и загружаете скриншот.
        <br />
        <br />
        Пример скриншота с преобладающей страной:
        <br />
        <br />
        <img src="@main/assets/img/settings/screenshots__inner10.png" alt="img" />
        <br />
        <br />
        <div ref="city-example"></div>
        Пример заполнения <b>преобладающего города в процентах</b> <br />
        <br />
        <img src="@main/assets/img/settings/screenshots__inner11.png" alt="img" />
        <br />
        <br />
        Модератор не примет скриншот без <span class="red"><b>процентов</b></span
        >. <br />
        <br />
        В статистике вашего аккаунта находите стороку с преобладающим городом в процентах.
        Заполняете в соответствующие поля и загружаете скриншот.
        <br />
        <br />
        Пример скриншота с преобладающим городом:
        <br />
        <br />
        <img src="@main/assets/img/settings/screenshots__inner12.png" alt="img" />
      </div>
    </div>
  </Modal>
  <!--/div-->
</template>

<script>
import Modal from '@main/components/reusable/modals/Modal.vue';

export default {
  components: {
    Modal,
  },
  props: {
    open: {
      type: Boolean,
    },
    openOn: { type: String, default: 'stories-example' },
  },
  mounted() {
    this.$refs[this.openOn].scrollIntoView();
  },
};
</script>

<style scoped>
.big-popup__body .screenshots__inner .scroll {
  overflow: auto !important;
}
</style>
