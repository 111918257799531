<template>
  <div v-if="apiState === 3" class="mp-app pr-md-2 pl-md-2">
    <info-bar v-if="currentUser && currentUser.mutualpr_profile" :profile="currentUser" />
    <main
      @click="setCurrentContext('')"
      :class="`content main pr no-bg ${mainClass}`"
      v-if="!isBlocked && isActive"
    >
      <router-view />
    </main>
    <main v-else-if="isBlocked" class="content main pr no-bg">
      <Blocked
        description="Вы заблокированы в сервисе обмена рекламой
        по причине жалоб на обман со статистикой"
      />
    </main>
    <main v-else-if="!isBlocked && !isActive" class="content main pr no-bg">
      <Blocked
        description="Вы не можете пользоваться сервисом ВП
      так как количество ваших подписчиков меньше 1000!"
      />
    </main>
    <AbuseModal />
  </div>
  <loader v-else />
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import SubscriptionPaymentModal from '@main/components/reusable/modals/SubscriptionPaymentModal.vue';
import enums from '@main/enums';
import { register } from '@mp/api/profile';
import AbuseModal from '@mp/moderation/AbuseModal.vue';
import InfoBar from '@mp/components/InfoBar.vue';
import Blocked from '@mp/components/Blocked.vue';
import Signup from '@mp/accounts/components/signup/Signup.vue';
import Loader from '../main/components/Loader.vue';

export default {
  components: {
    AbuseModal,
    Blocked,
    InfoBar,
    Loader,
  },
  data() {
    return {
      showLoader: true,
    };
  },
  computed: {
    ...mapState(['currentUser', 'apiState']),
    ...mapGetters('mutualpr', ['regComplete']),
    ...mapState('mutualpr', ['showAuthModal', 'apiState']),
    isBlocked() {
      if (this.currentUser && this.mutualpr_profile) {
        if (this.currentUser.mutualpr_profile.strikes === 0) {
          return true;
        }
      }
      return false;
    },
    isActive() {
      if (this.currentUser && this.currentUser.blogger_profile) {
        return true; // this.currentUser.blogger_profile.subscribers_count >= 1000;
      }
      return true;
    },
    mainClass() {
      return this.$route.meta.mainClass;
    },
  },
  methods: {
    ...mapMutations('mutualpr', ['setCurrentContext', 'setApiState']),
  },
  async mounted() {
    this.setApiState(enums.LOADING);
    if (this.currentUser && !this.currentUser.mutualpr_profile) {
      await register();
    }
    this.setApiState(enums.LOADED);
  },
};
</script>

<style scoped>
.form-auth {
  padding: 2em;
}
.btn--login {
  margin-top: 2em;
}
.input-auth:nth-child(2n) {
  margin-top: 1em;
}
.login__error-msg {
  font-size: 12px;
  line-height: 14px;
  margin-top: 10px;
  color: #ff0000;
}
.popup-on {
  padding-top: 40px;
}
.header__btn-login--main {
  padding: 8.5px 15px !important;
}
.header_noshadow {
  box-shadow: none !important;
}
.link-blue {
  display: inline;
}
.no-bg {
  background: none;
}
</style>
<style scoped>
.multiselect__tag {
  background: #fadb67;
  color: rgb(25, 34, 41);
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #fadb67;
}
.multiselect__tag-icon::after {
  color: rgb(25, 34, 41);
}
.multiselect__option--highlight {
  background: #fadb67;
  color: rgb(25, 34, 41);
}
.form-control {
  font-size: 14px;
}
.popup-verify__content {
  overflow: auto !important;
}
.no-shadow {
  box-shadow: none !important;
}
.vp_profile-overlay {
  opacity: 0.7;
  color: black;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
  padding: 200px;
}
.coverage__unmatch-content {
  margin: 30px;
}
.footer {
  flex-direction: column;
}
.mp-app {
  margin-top: -50px;
}
</style>
