<template>
  <div v-if="!vpClosed" class="info-bar__wrapper">
    <div class="values__block--mp">
      <div class="points__values">
        <span style="margin-right: 2px">{{ profile.mutualpr_profile.points }}</span>
        <img class="values__badge" src="@main/assets/img/svg/point.svg" />
      </div>
      <div class="lifes__values">
        <span style="margin-right: 2px"> {{ profile.mutualpr_profile.strikes }}</span>
        <img class="values__badge" src="@main/assets/img/svg/life.svg" />
      </div>
    </div>
    <div v-if="subscrDaysLeft" class="premium-left__wrapper">
      <p class="premium-left__text">Осталось {{ subscrDaysLeft }} дней подписки</p>
      <b-button variant="primary" class="btn--blue btn--premium__update" @click="showingPriceModal"
        >Продлить</b-button
      >
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['vpClosed']),
    subscrDaysLeft() {
      const now = new Date();
      const end = new Date(this.profile.mutualpr_profile.end_subscription.slice(0, 10));
      const difference = end - now;
      const days = Math.ceil(difference / 1000 / 60 / 60 / 24);
      if (days > 0) {
        return days;
      }
      return null;
    },
  },
  methods: {
    ...mapMutations('mutualpr', ['showingPriceModal']),
  },
};
</script>

<style scoped>
.info-bar__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  margin-top: 15px;
  margin-bottom: -45px;
  z-index: 1;
}
.premium-left__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.premium-left__text {
  color: #6a7b83;
  margin-right: 15px;
  font-size: 13.9px;
}
.btn--premium__update {
  font-size: 12px !important;
  height: 30px !important;
  line-height: 11px !important;
  border-radius: 7px !important;
}
.values__badge {
  width: 17px;
  height: 17px;
  display: inline-block;
}
.values__block--mp {
  display: flex;
  justify-content: space-between;
  width: 65px;
}
.values__bar {
  width: 100%;
}
@media (max-width: 768px) {
  .premium-left__wrapper {
    width: 300px;
    justify-content: flex-end;
  }
}
@media (max-width: 376px) {
  .premium-left__wrapper {
    width: 200px !important;
    justify-content: flex-end;
  }
  .premium-left__text {
    line-height: 15px;
    text-align: end;
  }
}
@media (max-width: 330px) {
  .premium-left__text {
    margin-right: 9px;
  }
}
</style>
