<template>
  <div>
    <div v-if="!currentUser.blogger_profile.instagram_verified || forceChange">
      <div v-if="noProfile || back">
        <p class="verification_text">
          Карточки блогера или активного профиля ВП не обнаружено, введите логин аккаунта чтобы
          продолжить.
        </p>
        <label for="iname"> Логин </label>
        <input
          v-model="instaname"
          type="text"
          id="iname"
          placeholder=""
          required="required"
          class="input popup-search-insta__input input-verif big"
        />
        <div
          :style="{ 'margin-bottom': verificationError ? '20px' : '5px' }"
          :class="{ btns_verify: true, bottom: !verificationError }"
        >
          <button type="button" class="btn--border" @click="() => $emit('back')">Назад</button>
          <button :disabled="loading" @click="getCode" class="btn--orange code_done-button">
            Далее
          </button>
        </div>
      </div>
      <div v-else>
        <p class="verification_text">
          Чтобы ваша карточка появилась в списке ВП на завтра, вам необходимо
          <strong
            >подтвердить свой аккаунт - @{{
              currentUser.blogger_profile.instaname || instaname
            }}.</strong
          >
          <br />
          <br />
          Для этого нужно <strong>вписать код в шапку профиля</strong> на время регистрации. Пример
          кода в шапке профиля ниже.
        </p>
        <img class="img_verify" src="../../../images/auth/verify_example.png" />
        <p class="copy_txt_mark">Скопируйте код</p>
        <div class="btns_verify">
          <input
            v-model="code"
            type="text"
            readonly="readonly"
            class="input popup-search-insta__input input-verif"
          />
          <button
            v-clipboard:copy="code"
            v-clipboard:success="() => (copied = true)"
            class="btn--border btn_copy-verify"
          >
            {{ copied ? 'Cкопировано' : 'Скопировать' }}
          </button>
        </div>
        <p class="popup-verify__text error small" v-if="verificationError">
          Мы не обнаружили код в шапке вашего профиля, поставьте его туда, иначе вы не сможете
          пройти регистрацию.
        </p>
        <div :class="{ btns_verify: true, bottom: !verificationError }">
          <button type="button" class="btn--border" @click="() => (back = true)">
            Изменить логин
          </button>
          <button
            v-if="
              currentUser.blogger_profile.instaname === instaname &&
              currentUser.blogger_profile.instagram_verified
            "
            :disabled="loading"
            @click="() => $emit('next')"
            class="btn--orange code_done-button"
            title="Ваш аккаунт верифицирован!"
          >
            Пропустить
          </button>
          <button v-else :disabled="loading" @click="verify" class="btn--orange code_done-button">
            Код поставлен
          </button>
        </div>
      </div>
    </div>
    <div class="verified__window--auth" v-else>
      <img src="@main/assets/done_icon.png" />
      <p class="verified__window--text">Ваш аккаунт уже прошел аутентификацию!</p>
      <div class="verified__window--buttons">
        <b-button @click="() => $emit('back')" variant="outline-default">Назад</b-button>
        <b-button @click="forceChange = true" variant="outline-default">Изменить логин</b-button>
        <b-button @click="() => $emit('next')" variant="warning">Далее</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { register } from '@mp/api/profile';
import bloggers from '@main/api/bloggers';
import instagram from '@main/api/instagram';

export default {
  data: () => ({
    verificationError: false,
    loginError: false,
    forceChange: false,
    code: null,
    copied: false,
    loading: false,
    back: false,
    instaname: '',
  }),
  methods: {
    ...mapActions(['loadUser']),
    verify() {
      this.loading = true;
      const instaname = this.instaname.replace(/\s|@mp/g, '');
      instagram
        .getAccountGraphql(instaname)
        .then((res) => {
          bloggers
            .checkInstagramCode(res, false)
            .then((resCode) => {
              if (resCode.success) {
                this.$emit('next');
              } else {
                this.verificationError = true;
              }
            })
            .catch((error) => {
              this.verificationError = true;
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch((er) => {
          bloggers
            .checkInstagramCode(null, false)
            .then((resCode) => {
              if (resCode.success) {
                this.$emit('next');
              } else {
                this.verificationError = true;
              }
            })
            .catch((err) => {
              this.verificationError = true;
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getCode() {
      this.loading = true;
      this.copied = false;
      const instaname = this.instaname.replace(/[^A-Za-z0-9]/g, '');
      const response = await register(instaname);
      if (response.success) {
        await this.loadUser();
        const responseCode = await bloggers.getInstagramCode();
        if (responseCode.success) {
          this.code = responseCode.code;
          this.back = false;
        }
      }
      this.loading = false;
    },
  },
  computed: {
    ...mapState(['currentUser']),
    noProfile() {
      return (
        !this.currentUser.blogger_profile ||
        !this.currentUser.blogger_profile.instaname ||
        !this.code
      );
    },
  },
  async mounted() {
    if (this.currentUser) {
      if (this.currentUser.blogger_profile.instaname) {
        await this.getCode(this.currentUser.blogger_profile.instaname);
      }
    }
  },
};
</script>

<style scoped>
.error {
  color: red;
  border: none !important;
}
.button__block-verification {
  margin-top: 20px;
}
.verification_text {
  font-size: 14px;
  margin-bottom: 15px;
}
.btns_verify {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.input-verif {
  height: 36px;
  width: 53%;
  margin-bottom: 0px;
  margin-right: 14px;
}
.img_verify {
  margin-bottom: 15px;
}
.copy_txt_mark {
  margin-bottom: 8px;
}
.error_verify-text {
  font-size: 13.3px;
  margin-bottom: 15px;
}
.code_done-button {
  height: 36px;
  width: 45% !important;
  max-width: none;
}
.btn_verify_back {
  text-align: center;
}
.bottom {
  margin-top: 45px;
}
.small {
  font-size: 13.5px;
  line-height: 16px;
}
.btn_copy-verify {
  width: 40%;
}
.big {
  width: 100% !important;
}
.verified__window--auth {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center !important;
  min-height: 230px;
}
.verified__window--text {
  text-align: center;
}
.verified__window--buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}
</style>
